import { themeVars } from '@libs/shared-ui-styles';
import { useSVGSprite } from '../DynamicSVGSpritesheetProvider';

const getBlueGreenGradientID = (id: string) => `${id}-bg-grad`;
const getRedYellowGradientID = (id: string) => `${id}-ry-grad`;

// The omni icon is very heavy and resource intensive
// to render individually a ton of times on the page.
// To optimize performance, we'll render the icon once somewhere
// on the page with this source component, and then we can
// re-use it with the OmniIcon component
const OmniIconSprite = ({ id }: { id: string }) => (
  <>
    <symbol id={id} viewBox="0 0 16 17">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 2C3.75 1.58579 4.08579 1.25 4.5 1.25H11.5C11.9142 1.25 12.25 1.58579 12.25 2V15C12.25 15.4142 11.9142 15.75 11.5 15.75H4.5C4.08579 15.75 3.75 15.4142 3.75 15V2Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 2.75C13.4142 2.75 13.75 3.08579 13.75 3.5V13.5C13.75 13.9142 13.4142 14.25 13 14.25H3C2.58579 14.25 2.25 13.9142 2.25 13.5V3.5C2.25 3.08579 2.58579 2.75 3 2.75H13Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 4.25C14.9142 4.25 15.25 4.58579 15.25 5V12C15.25 12.4142 14.9142 12.75 14.5 12.75H1.5C1.08579 12.75 0.75 12.4142 0.75 12V5C0.75 4.58579 1.08579 4.25 1.5 4.25H14.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </symbol>
    {/* Blue/green gradient */}
    <radialGradient
      id={getBlueGreenGradientID(id)}
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(1.24069 3.37373) rotate(42.3794) scale(18.3614 30.0121)"
    >
      <stop stopColor={themeVars.color.ai.color1} />
      <stop offset="1" stopColor={themeVars.color.brand.default} />
    </radialGradient>
    {/* Pink/yellow gradient */}
    <radialGradient
      id={getRedYellowGradientID(id)}
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(1.24069 3.37373) rotate(42.3794) scale(18.3614 30.0121)"
    >
      <stop stopColor={themeVars.color.ai.color2} />
      <stop offset="1" stopColor={themeVars.color.ai.color1} />
    </radialGradient>
  </>
);

/**
 * Omni icon with a gradient fill. This icon is fairly complex and can appear a large number of times on the same page,
 * so we are using the SVGIconSourceProvider to optimize performance by rendering the source icon once and re-use it for every
 * OmniIcon instance.
 */
export const OmniIcon = ({
  gradientType = 'blueGreen',
  ...props
}: React.ComponentPropsWithoutRef<'svg'> & {
  gradientType?: 'blueGreen' | 'redYellow';
}) => {
  // Register the OmniIconSprite component so we can reference it via <use>
  const iconID = useSVGSprite(OmniIconSprite);

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <use
        href={`#${iconID}`}
        stroke={`url(#${
          gradientType === 'blueGreen'
            ? getBlueGreenGradientID(iconID)
            : getRedYellowGradientID(iconID)
        })`}
      />
    </svg>
  );
};

interface OmniIconSolidProps extends React.ComponentPropsWithoutRef<'svg'> {
  color?: string;
}

/**
 * Omni icon with no gradient.
 */
export const OmniIconSolid = ({ color = 'currentColor' }: OmniIconSolidProps) => (
  <svg width="20" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 2.75C5.625 2.12868 6.12868 1.625 6.75 1.625H17.25C17.8713 1.625 18.375 2.12868 18.375 2.75V22.25C18.375 22.8713 17.8713 23.375 17.25 23.375H6.75C6.12868 23.375 5.625 22.8713 5.625 22.25V2.75Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 3.875C20.1213 3.875 20.625 4.37868 20.625 5V20C20.625 20.6213 20.1213 21.125 19.5 21.125H4.5C3.87868 21.125 3.375 20.6213 3.375 20V5C3.375 4.37868 3.87868 3.875 4.5 3.875H19.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.75 6.125C22.3713 6.125 22.875 6.62868 22.875 7.25V17.75C22.875 18.3713 22.3713 18.875 21.75 18.875H2.25C1.62868 18.875 1.125 18.3713 1.125 17.75V7.25C1.125 6.62868 1.62868 6.125 2.25 6.125H21.75Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
